<template>
  <v-container>
    <v-container>
      <v-form
        ref="form"
        @submit.prevent="enviar"
        lazy-validation
        class="painel"
      >
        <v-text-field
          outlined
          label="Nome"
          :rules="[rules.required]"
          type="text"
          v-model="name"
          required
        />
        <v-text-field
          outlined
          :rules="[rules.required]"
          label="Apelido"
          type="text"
          v-model="apelido"
          required
        />
        <v-text-field
          v-model="email"
          :rules="[rules.required, rules.email]"
          outlined
          label="E-mail"
          type="mail"
          required
        />
        <Rotulo nome="">
          <VuePhoneNumberInput
            default-country-code="PT"
            :preferred-countries="preferredCountries"
            :countryCode="countryCode"
            v-model="phone"
            :rules="[rules.required]"
          />
        </Rotulo>
        <Rotulo nome="Minha experiência na desossa de suíno">
          <v-rating
            length="5"
            color="#888"
            background-color="#DDD"
            size="24"
            v-model="rating"
            :rules="[rules.required]"
          ></v-rating>
        </Rotulo>
        <Rotulo nome="Pretenção salarial">
          <v-slider
            color="#DDD"
            track-color="#555"
            thumb-color="#555"
            min="800"
            step="50"
            max="1100"
            v-model="salary"
            :thumb-size="30"
            thumb-label="always"
            prepend-icon="mdi-currency-eur"
          ></v-slider>
        </Rotulo>
        <Rotulo
          nome="Tenho disponibilidade para mudar de região dentro de Portugal"
        >
          <input type="checkbox" v-model="change" />
        </Rotulo>

        <v-btn
          v-if="!send"
          x-large
          block
          color="success"
          :disabled="valid"
          type="submit"
          ><b>Enviar</b></v-btn
        >
        <v-container v-if="send">
          <Alert
            message="Dados enviados com sucesso! Obrigado por se cadastrar"
            type="succes"
            color="green"
          />
        </v-container>
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Rotulo from "./Rotulo.vue";
import Alert from "./../Alert.vue";
export default {
  components: { Rotulo, Alert, VuePhoneNumberInput },
  data() {
    return {
      valid: false,
      send: false,
      name: "",
      apelido: "",
      countryCode: "",
      phone: "",
      email: "",
      change: "",
      salary: null,
      rating: null,
      preferredCountries: ["PT", "BR", "FR"],
      rules: {
        required: (value) => !!value || "Campo Obrigatório.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "E-mail inválido.";
        },
      },
    };
  },
  methods: {
    enviar() {
      if (this.$refs.form.validate()) {
        this.$http.post("desossa.json", {
          name: this.name,
          apelido: this.apelido,
          phone: this.phone,
          email: this.email,
          salary: this.salary,
          change: this.change,
          rating: this.rating,
        });
        this.send = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
