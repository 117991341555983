<template>
  <v-alert
    v-model="alert"
    dismissible
    :type="type"
    :color="color"
    border="left"
    elevation="2"
    colored-border
    :icon="icon"
    transition="scale-transition"
  >
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  props: ["message","icon", "type", "color"],
};
</script>

<style lang="scss" scoped></style>
