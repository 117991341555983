<template>
  <v-img :aspect-ratio="16 / 9" src="./../assets/background.jpg">
    <v-container>
      <v-card class="card mb-5" width="450">
        <v-card-title><h3>Recrutamento</h3></v-card-title>
        <v-card-text>
          <h3>
            És desossador e quer fazer parte de uma grande equipa de
            profissionais? Preencha os campos abaixo, entraremos em contacto o
            mais breve possível.
          </h3>
        </v-card-text>
        <div>
          <Form />
        </div>
      </v-card>
    </v-container>
  </v-img>
</template>

<script>
import Form from "./../components/Forms/Form.vue";
export default {
  components: { Form },
};
</script>

<style scoped>
.card {
  float: right;
}
</style>
