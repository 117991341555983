<template>
  <v-container>
    <h1>Estamos desenvolvendo um ambiente mais dinâmico.</h1>
    <h3>Em breve voltaremos com novas ofertas de <a href="/desossador">emprego</a>.</h3>
  </v-container>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>